import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, Container, Row, Col, Pagination } from 'react-bootstrap';
import ApiRequest from '../../Utils/AxiosHelper';
import './Home.css';

const HomeScreen = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit] = useState(12);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalSessions, setTotalSessions] = useState(0);
  const [status, setStatus] = useState('Active');

  const fetchSessions = useCallback(async (search = '') => {
    try {
      setLoading(true);
      const queryParams = {
        page: page,
        limit: limit,
        status: status,
        ...(search && { title: search })
      };

      const response = await ApiRequest(
        'GET',
        '/admin/sessions',
        null,
        {},
        queryParams,
        true
      );

      if (response.data) {
        setSessions(response.data.sessions);
        setTotalPages(response.data.totalPages);
        setTotalSessions(response.data.totalSessions);
      } else {
        console.error('Error fetching sessions:', response.message);
      }
    } catch (error) {
      console.error('Error fetching sessions:', error);
    } finally {
      setLoading(false);
    }
  }, [page, limit, status]);

  const debouncedFetch = useMemo(() => {
    let timeout;
    const debouncedFunction = (searchValue) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        fetchSessions(searchValue);
      }, 500);
    };
    debouncedFunction.cancel = () => clearTimeout(timeout);
    return debouncedFunction;
  }, [fetchSessions]);

  useEffect(() => {
    debouncedFetch(searchTerm);
    return () => debouncedFetch.cancel();
  }, [searchTerm, debouncedFetch]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setPage(1);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetchSessions(searchTerm);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0); // Scroll to top when page changes
  };

  return (
    <Container className="my-5">
      <Row>
        <Col className="d-flex justify-content-between align-items-center mb-4">
          <h2>Sessions</h2>
          <div className="d-flex gap-3">
            <div className="btn-group">
              <Button 
                variant={status === 'Active' ? 'primary' : 'outline-primary'}
                onClick={() => setStatus('Active')}
              >
                Active Sessions
              </Button>
              <Button 
                variant={status === 'All' ? 'primary' : 'outline-primary'}
                onClick={() => setStatus('All')}
              >
                All Sessions
              </Button>
            </div>
            <Link to="/Create-session">
              <Button variant="primary">Create New Session</Button>
            </Link>
          </div>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <input
            type="search"
            className="form-control"
            placeholder="Search sessions..."
            value={searchTerm}
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
          />
        </Col>
      </Row>

      {loading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <Row className="g-4">
          {Array.isArray(sessions) && sessions.length > 0 ? (
            sessions.map((session) => (
              <Col xxl={4} xl={4} lg={6} md={12} sm={12} key={session._id}>
                <Card className="h-100 session-card p-0">
                  <Card.Img 
                    variant="top" 
                    src={session.coverImage} 
                    alt="Session Cover"
                    className="session-image w-100"
                    onError={(e) => {
                      e.target.src = 'fallback-image-url.jpg';
                      e.target.onerror = null;
                    }}
                  />
                  <Card.Body className="d-flex flex-column">
                    <Card.Title className="session-title">{session.title}</Card.Title>
                    <Card.Text className="session-description">
                      {session.description}
                    </Card.Text>
                    <div className="session-details mt-auto">
                      <div>Monthly Fee: Rs. {session.monthlyFee}</div>
                      <div>Start Date: {new Date(session.startDate).toLocaleDateString()}</div>
                      <div>End Date: {new Date(session.endDate).toLocaleDateString()}</div>
                      <div className="mb-3">Registration Deadline: {new Date(session.registrationDeadline).toLocaleDateString()}</div>
                      <Link to={`/sessionInfo/${session._id}`} state={{ sessionData: session }}>
                        <Button variant="primary" className="w-100">View Session</Button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col>
              <p className="text-center">No sessions found</p>
            </Col>
          )}
        </Row>
      )}

      {!loading && sessions.length > 0 && (
        <Row className="mt-4">
          <Col className="d-flex justify-content-center align-items-center">
            <Pagination>
              <Pagination.First 
                onClick={() => handlePageChange(1)} 
                disabled={page === 1}
              />
              <Pagination.Prev 
                onClick={() => handlePageChange(page - 1)} 
                disabled={page === 1}
              />
              
              {[...Array(totalPages)].map((_, idx) => {
                const pageNumber = idx + 1;
                // Show current page, first, last, and 1 page before and after current
                if (
                  pageNumber === 1 ||
                  pageNumber === totalPages ||
                  (pageNumber >= page - 1 && pageNumber <= page + 1)
                ) {
                  return (
                    <Pagination.Item
                      key={pageNumber}
                      active={pageNumber === page}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </Pagination.Item>
                  );
                } else if (pageNumber === page - 2 || pageNumber === page + 2) {
                  return <Pagination.Ellipsis key={pageNumber} />;
                }
                return null;
              })}

              <Pagination.Next 
                onClick={() => handlePageChange(page + 1)} 
                disabled={page === totalPages}
              />
              <Pagination.Last 
                onClick={() => handlePageChange(totalPages)} 
                disabled={page === totalPages}
              />
            </Pagination>
          </Col>
        </Row>
      )}

      {!loading && sessions.length > 0 && (
        <Row className="mt-2">
          <Col className="text-center text-muted">
            Showing {sessions.length} of {totalSessions} sessions
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default HomeScreen;
