import axios from "axios";
import { removeAllCookies, getAuthToken } from "./CookiesHelper";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const ApiRequest = async (method, url, body = null, headers = {}, params = {}, sendToken = false) => {
  const URL = BASE_URL+url;

  let requestHeaders = {
    "Content-Type": "application/json",
    ...headers,
  };

  if (sendToken) {
    const token = getAuthToken();
    if (token) {
      requestHeaders.Authorization = `Bearer ${token}`;
    }
    else{
      removeAllCookies();
      window.location.href = '/';
    }
  }
  
  const config = {
    method: method,
    url: URL,
    headers: requestHeaders,
    params: params,
    data: body,
  };

  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    if (error.response) {
      return { success: false, message: error.response.data.message || error.response.statusText };
    } else if (error.request) {
      return { success: false, message: "No response received from the server." };
    } else {
      return { success: false, message: error.message };
    }
  }
};

export default ApiRequest;
