import React from 'react';
import { Modal } from 'react-bootstrap';

const getYoutubeEmbedUrl = (url) => {
  try {
    // Handle different YouTube URL formats
    if (url.includes('youtu.be')) {
      // Handle shortened URLs
      const videoId = url.split('youtu.be/')[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (url.includes('youtube.com')) {
      // Handle regular URLs
      const videoId = url.split('v=')[1]?.split('&')[0];
      if (!videoId) throw new Error('Could not extract video ID');
      return `https://www.youtube.com/embed/${videoId}`;
    }
    return url;
  } catch (error) {
    console.error('Error parsing YouTube URL:', error, 'URL:', url);
    return url;
  }
};

const VideoModal = ({ show, handleClose, videoUrl, isYoutubeVideo }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Lecture Video</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isYoutubeVideo ? (
          <iframe
            className="lecture-video"
            src={getYoutubeEmbedUrl(videoUrl)}
            title="Lecture Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ width: '100%', height: '500px' }} // Adjust height as needed
          />
        ) : (
          <video 
            className="lecture-video"
            controls
            preload="metadata"
            style={{ width: '100%', height: '500px' }} // Adjust height as needed
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default VideoModal;
