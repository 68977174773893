import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Button, Container, Row, Col, Image } from 'react-bootstrap';
import { useLocation, Link, useNavigate, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import ApiRequest from '../../Utils/AxiosHelper';
import './SessionInfo.css';
import { toast } from 'react-toastify';
import ConfirmationModal from '../Modals/DeleteSessionModal';
import VideoModal from '../Modals/VideoModal';

const SessionInfo = () => {
  const [activeTab, setActiveTab] = useState('lectures');
  const [lectures, setLectures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;

  const location = useLocation();
  const sessionData = location.state?.sessionData;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [sessionIdToDelete, setSessionIdToDelete] = useState(null);
  const [activeVideo, setActiveVideo] = useState(null);
  const [isYoutubeVideo, setIsYoutubeVideo] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [isDeletingLecture, setIsDeletingLecture] = useState(false);
  const [lectureIdToDelete, setLectureIdToDelete] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const { id } = useParams();

  const fetchLectures = async (pageNum = page) => {
    try {
      setLoading(true);
      const response = await ApiRequest(
        'GET',
        '/admin/lectures',
        null,
        {},
        {
          sessionId: sessionData?._id,
          limit,
          page: pageNum
        },
        true
      );

      if (response.data) {
        const newLectures = response.data.lectures;
        if (pageNum === 1) {
          setLectures(newLectures);
        } else {
          setLectures(prev => [...prev, ...newLectures]);
        }
        
        // Update hasMore based on totalPages
        setHasMore(response.data.currentPage < response.data.totalPages);
      } else {
        console.error('Error fetching lectures:', response.message);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    setPage(prev => prev + 1);
    fetchLectures(page + 1);
  };

  const getYoutubeEmbedUrl = (url) => {
    try {
      // Handle different YouTube URL formats
      if (url.includes('youtu.be')) {
        // Handle shortened URLs
        const videoId = url.split('youtu.be/')[1];
        return `https://www.youtube.com/embed/${videoId}`;
      } else if (url.includes('youtube.com')) {
        // Handle regular URLs
        const videoId = url.split('v=')[1]?.split('&')[0];
        if (!videoId) throw new Error('Could not extract video ID');
        return `https://www.youtube.com/embed/${videoId}`;
      }
      return url;
    } catch (error) {
      console.error('Error parsing YouTube URL:', error, 'URL:', url);
      return url;
    }
  };


  useEffect(() => {
    if (sessionData?._id) {
      setPage(1);
      fetchLectures(1);
    }
  }, [sessionData?._id]);

  const handleDeleteSession = async () => {
    try {
      const response = await ApiRequest(
        'DELETE',
        `/admin/session/${sessionIdToDelete}`,
        {},
        {},
        {},
        true
      );
      if(response.status === 200){
        toast.success('Session deleted successfully!');
        navigate('/sessions');
      } else {
        toast.error(response.message || 'Failed to delete session.');
      }
    } catch (error) {
      toast.error('Failed to delete session.');
      console.error('Error deleting session:', error);
    } finally {
      closeModal();
    }
  };

  const openDeleteModal = (sessionId, title) => {
    setSessionIdToDelete(sessionId);
    setLectureIdToDelete(null);
    setShowModal(true);
    setModalTitle(title);
  };

  const closeModal = () => {
    setShowModal(false);
    setSessionIdToDelete(null);
    setActiveVideo(null);
    setShowVideoModal(false);
  };

  const openVideoModal = (lecture) => {
    setActiveVideo(lecture.lectureLink);
    setIsYoutubeVideo(lecture.isYoutubeVideo);
    setShowModal(false);
    setShowVideoModal(true);
  };

  const openDeleteLectureModal = (lectureId, title) => {
    setLectureIdToDelete(lectureId);
    setSessionIdToDelete(null);
    setShowModal(true);
    setModalTitle(title);
  };

  const handleDeleteLecture = async () => {
    setIsDeletingLecture(true);
    try {
      const response = await ApiRequest(
        'DELETE',
        `/admin/lecture/${lectureIdToDelete}`,
        {sessionId: id},
        {},
        {},
        true
      );
      if(response.status === 200){
        toast.success('Lecture deleted successfully!');
        setTimeout(()=>window.location.reload(),1000);
      } else {
        toast.error(response.message || 'Failed to delete lecture.');
      }
    } catch (error) {
      toast.error('Failed to delete Lecture.');
      console.error('Error deleting Lecture:', error);
    } finally {
      setIsDeletingLecture(false);
      closeModal();
    }
  };

  return (
    <Container className="session-info-container my-5">
      <Row className="mb-4">
        <Col md={4}>
          <Image 
            src={sessionData?.coverImage} 
            alt="Session Cover" 
            className="w-100 mb-3"
            onError={(e) => {
              e.target.src = 'fallback-image-url.jpg';
              e.target.onerror = null;
            }}
          />
        </Col>
        <Col md={8}>
          <Row>
            <Col md={8}>
              <h2>{sessionData?.title}</h2>
            </Col>
            <Col md={4}>
              <Button variant='danger' onClick={() => openDeleteModal(sessionData?._id, sessionData?.title)}>Delete Session</Button>
            </Col>
          </Row>
          <p className="text-muted">{sessionData?.description}</p>
          <div className="session-details">
            <p><strong>Monthly Fee:</strong> Rs. {sessionData?.monthlyFee}</p>
            <p><strong>Start Date:</strong> {new Date(sessionData?.startDate).toLocaleDateString()}</p>
            <p><strong>End Date:</strong> {new Date(sessionData?.endDate).toLocaleDateString()}</p>
            <p><strong>Registration Deadline:</strong> {new Date(sessionData?.registrationDeadline).toLocaleDateString()}</p>
          </div>
        </Col>
      </Row>
      
      <Row className="justify-content-center">
        <Col md={8}>
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="justify-content-center session-tabs"
            variant="pills"
            id="session-tabs"
          >
            <Tab eventKey="lectures" title="Lectures">
              <div className="tab-content">
                <Row className="mb-3">
                  <Col className="d-flex justify-content-end">
                    <Link 
                      to="/upload-lecture" 
                      state={{ sessionId: sessionData?._id }}
                    >
                      <Button variant="primary" className="add-button">
                        + Add New Lecture
                      </Button>
                    </Link>
                  </Col>
                </Row>

                <InfiniteScroll
                  dataLength={lectures.length}
                  next={loadMore}
                  hasMore={hasMore}
                  loader={
                    <div className="text-center my-3">
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  }
                  endMessage={
                    lectures.length > 0 && !hasMore ? (
                      <p className="text-center text-muted my-3">
                        No more lectures to load.
                      </p>
                    ) : null
                  }
                >
                  <ul className="lecture-list">
                    {lectures.map((lecture) => (
                      <li key={lecture._id} className="lecture-item">
                        <div className="lecture-video-container">
                          {lecture.thumbnailLink ? (
                            <img 
                              src={lecture.thumbnailLink} 
                              alt={lecture.title} 
                              className="thumbnail" 
                              onClick={() => openVideoModal(lecture)}
                            />
                          ) : (
                            <div className="video-placeholder" onClick={() => openVideoModal(lecture)}>
                              <img 
                                src="/images/DefaultThumbnail.jpg"
                                alt="Default Thumbnail" 
                                className="thumbnail" 
                              />
                            </div>
                          )}
                        </div>
                        <div className="lecture-info d-flex justify-content-between">
                          <div className="lecture-title">{lecture.title}</div>
                          <button 
                            className='btn btn-danger' 
                            onClick={() => openDeleteLectureModal(lecture._id, lecture.title)}
                            disabled={isDeletingLecture}
                          >
                            {isDeletingLecture ? 'Deleting...' : 'Delete Lecture'}
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </InfiniteScroll>

                {lectures.length === 0 && !loading && (
                  <div className="text-center text-muted">
                    No lectures available
                  </div>
                )}
              </div>
            </Tab>

            <Tab eventKey="notes" title="Notes" disabled>
              <div className="tab-content">
                {/* Notes content removed */}
              </div>
            </Tab>
          </Tabs>
        </Col>
      </Row>

      <VideoModal 
        show={showVideoModal}
        handleClose={closeModal} 
        videoUrl={activeVideo} 
        isYoutubeVideo={isYoutubeVideo} 
      />

      <ConfirmationModal 
        show={showModal && (sessionIdToDelete !== null || lectureIdToDelete !== null)}
        handleClose={closeModal} 
        handleConfirm={lectureIdToDelete ? handleDeleteLecture : handleDeleteSession}
        title={modalTitle} 
      />
    </Container>
  );
};

export default SessionInfo;
