import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'; // Import NavLink
import {getUser, removeAllCookies} from '../../Utils/CookiesHelper.js';
import { useNavigate } from 'react-router-dom';

const CustomNavbar = () => {

  const navigate = useNavigate()
  const user = getUser();
  const handleLogout = () => {
    removeAllCookies();
    navigate('/');
    window.location.reload();
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container fluid className="px-3">
        <Navbar.Brand as={NavLink} to="/" className="me-auto">
          <img
            src="/images/pace-gk-logo.png" // replace with your logo's path
            width="30"
            height="30"
            className="d-inline-block align-top bg-white rounded-circle"
            alt="PACE GK logo"
          />
          <span className="ms-2">PACE GK</span>
        </Navbar.Brand>
        {user && (
          <>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mx-auto">
              <Nav.Link as={NavLink}  to="/sessions" className="active">
                  Sessions
                </Nav.Link>
                <Nav.Link as={NavLink}  to="/Create-session" className="active">
                  Create Session
                </Nav.Link>
                <Nav.Link as={NavLink} to="/upload-lecture" className="active">
                  Upload Lecture
                </Nav.Link>
                {/* <Nav.Link as={NavLink} to="/sessions" activeClassName="active">
                  Sessions
                </Nav.Link>
                <Nav.Link as={NavLink} to="/test-series" activeClassName="active">
                  Test Series
                </Nav.Link> */}
              </Nav>
              <Nav>
                <Button variant="outline-light" onClick={handleLogout}>Logout</Button>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
