import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SessionDropdown from "../Session/SessionDropDown.jsx";
import "./index.css";
import { useLocation } from 'react-router-dom';
import ApiRequest from "../../Utils/AxiosHelper.js";

const CHUNK_SIZE = 10 * 1024 * 1024; // 10MB chunk size

const getVideoThumbnail = (file) => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    video.addEventListener('loadeddata', () => {
      video.currentTime = 1; // Grab frame at 1 second
    });
    video.addEventListener('seeked', () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      resolve(canvas.toDataURL());
      URL.revokeObjectURL(video.src);
    });
  });
};

const VideoUploader = ({ uploaderId, onRemove, onUploadComplete, index }) => {
  const location = useLocation();
  const sessionIdFromState = location.state?.sessionId;

  const [newLecture, setNewLecture] = useState({
    title: "",
    sessionId: sessionIdFromState || "",
    thumbnail: "",
    thumbnailUrl: "",
    isYoutubeVideo: false,
    videoFile: null,
    videoPreviewUrl: "",
    youtubeUrl: "",
    status: "pending",
    progress: null,
  });

  const [uploadController, setUploadController] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const thumbnailInputRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    return () => {
      if (newLecture.status === 'completed' || newLecture.status === 'canceled') {
        localStorage.removeItem(`upload-progress-${uploaderId}`);
      }
      if (newLecture.thumbnailUrl) {
        URL.revokeObjectURL(newLecture.thumbnailUrl);
      }
    };
  }, [uploaderId, newLecture.status, newLecture.thumbnailUrl]);

  const handleInputChange = (field, value) => {
    setNewLecture((prev) => ({ ...prev, [field]: value }));
  };

  const handleThumbnailImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const thumbnailUrl = URL.createObjectURL(file);
      setNewLecture((prev) => ({
        ...prev,
        thumbnail: file,
        thumbnailUrl: thumbnailUrl,
      }));
    }
  };

  const handleRemoveThumbnail = () => {
    if (newLecture.thumbnailUrl) {
      URL.revokeObjectURL(newLecture.thumbnailUrl);
    }
    setNewLecture((prev) => ({
      ...prev,
      thumbnail: "",
      thumbnailUrl: "",
    }));
    if (thumbnailInputRef.current) {
      thumbnailInputRef.current.value = "";
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const videoPreviewUrl = await getVideoThumbnail(file);
      setNewLecture((prev) => ({ 
        ...prev, 
        videoFile: file,
        videoPreviewUrl: videoPreviewUrl
      }));
    }
  };

  const handleRemoveVideo = () => {
    if (newLecture.videoPreviewUrl) {
      URL.revokeObjectURL(newLecture.videoPreviewUrl);
    }
    setNewLecture((prev) => ({
      ...prev,
      videoFile: null,
      videoPreviewUrl: "",
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const uploadChunk = async (presignedUrl, chunk, fileType, controller) => {
    try {
      const response = await axios.put(presignedUrl, chunk, {
        headers: {
          'Content-Type': fileType,
        },
        signal: controller.signal,
      });
      
      return response;
    } catch (error) {
      if (error.response?.status === 404 && error.response?.data?.includes('NoSuchUpload')) {
        throw new Error('NoSuchUpload');
      }
      
      if (axios.isCancel(error)) {
        throw error;
      }
      
      console.error('Error uploading chunk:', {
        status: error.response?.status,
        message: error.message,
        url: presignedUrl
      });
      
      throw error;
    }
  };

  const completeUpload = async (uploadId, parts) => {
    try {
      const formData = new FormData();
      formData.append('uploadId', uploadId);
      formData.append('parts', JSON.stringify(parts.sort((a, b) => a.PartNumber - b.PartNumber)));
      formData.append('fileName', newLecture.videoFile.name);
      formData.append('title', newLecture.title);
      formData.append('sessionId', newLecture.sessionId);
      if (newLecture.thumbnail) {
        formData.append('thumbnail', newLecture.thumbnail);
      }

      await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/lecture/complete-upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error('Error completing upload:', error);
      throw error;
    }
  };

  const uploadVideoFile = async (controller) => {
    let currentUploadId = null;
    let key = null;
    
    try {
      // Initiate the upload and store the uploadId
      const initiateResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/lecture/initiate-upload`,
        {
          fileName: newLecture.videoFile.name,
          fileType: newLecture.videoFile.type,
          sessionId: newLecture.sessionId,
        },
        { signal: controller.signal }
      );
      currentUploadId = initiateResponse.data.uploadId;
      key = initiateResponse.data.key;
      const fileParts = await uploadFileChunks(currentUploadId, key, controller);
      await completeUpload(currentUploadId, fileParts);

      setNewLecture((prev) => ({
        ...prev,
        status: "completed",
        progress: 100,
      }));
      onUploadComplete(uploaderId);
    } catch (err) {
      if (axios.isCancel(err)) {
      } else if (err.message?.includes('NoSuchUpload')) {
        // If the upload session expired, restart the upload
        setNewLecture(prev => ({ ...prev, progress: 0 }));
        await uploadVideoFile(controller); // Retry the entire upload
      } else {
        console.error(err);
        setNewLecture((prev) => ({ ...prev, status: "failed" }));
        alert(err.response?.data?.message || "An error occurred during upload");
      }
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newLecture.videoFile && !newLecture.isYoutubeVideo) return;

    setIsUploading(true);
    setNewLecture((prev) => ({ ...prev, progress: 0.1 }));

    const controller = new AbortController();
    setUploadController(controller);

    if (!newLecture.isYoutubeVideo) {
      await uploadVideoFile(controller);
    } else {

      const formData = new FormData();
      formData.append('title', newLecture.title);
      formData.append('sessionId', newLecture.sessionId);
      formData.append('link', newLecture.youtubeUrl);
      if (newLecture.thumbnail) {
        formData.append('thumbnail', newLecture.thumbnail);
      }

      await ApiRequest('post','/admin/lecture/create-lecture',formData,
        { 'Content-Type': 'multipart/form-data' },
      {},true);

      setNewLecture((prev) => ({
        ...prev,
        status: "completed",
        progress: 100,
      }));
      onUploadComplete(uploaderId);

      setIsUploading(false);
    }
  };

  const uploadFileChunks = async (uploadId, key, controller) => {
    const file = newLecture.videoFile;
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    const fileParts = [];
    
    for (let partNumber = 1; partNumber <= totalChunks; partNumber++) {
      const start = (partNumber - 1) * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, file.size);
      const chunk = file.slice(start, end);

      let retries = 0;
      const maxRetries = 3;

      while (retries < maxRetries) {
        try {
          const presignedUrlResponse = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/admin/lecture/upload-part`,
            {
              uploadId,
              partNumber,
              key,
            },
            { signal: controller.signal }
          );

          const { presignedUrl } = presignedUrlResponse.data;
          const uploadResponse = await uploadChunk(presignedUrl, chunk, file.type, controller);
          
          const etag = uploadResponse.headers.etag || uploadResponse.headers.ETag;
          fileParts.push({
            ETag: etag,
            PartNumber: partNumber,
          });

          const progress = Math.round((fileParts.length / totalChunks) * 100);
          setNewLecture(prev => ({ ...prev, progress }));
          break; // Success, move to next chunk

        } catch (err) {
          if (err.message === 'NoSuchUpload') {
            throw err; // Propagate this error to restart the entire upload
          }
          
          retries++;
          if (retries === maxRetries) {
            throw err;
          }
          
          // Create a local constant for the delay time
          const delayTime = 1000 * retries;
          await new Promise(resolve => setTimeout(resolve, delayTime));
        }
      }
    }

    return fileParts;
  };

  const handleCancelUpload = () => {
    if (uploadController) {
      uploadController.abort();
      setNewLecture((prev) => ({ ...prev, status: "canceled" }));
      setIsUploading(false);
      localStorage.removeItem(`upload-progress-${uploaderId}`);
    }
    onRemove();
  };

  return (
    <div className="video-uploader">
      <div className="uploader-header">
        <h2 className="uploader-title">Add Lecture</h2>
        {((!isUploading && newLecture.status !== 'completed') && index !== 0) && (
          <button
            onClick={handleCancelUpload}
            className="remove-uploader-button"
          >
            ✖
          </button>
        )}
      </div>

      {newLecture.status === "completed" ? (
        <div className="upload-success d-flex">
          <div className="success-icon">✓</div>
          <h3 className="success-message">{newLecture.title || "Video"} has been uploaded successfully!</h3>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="upload-form">
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              className="form-input"
              value={newLecture.title}
              onChange={(e) => handleInputChange("title", e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="session">Session</label>
            <SessionDropdown
              value={newLecture.sessionId}
              onChange={(value) => handleInputChange("sessionId", value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="thumbnail">Thumbnail</label>
            <div className="thumbnail-preview-container">
              <div className="thumbnail-input-wrapper">
                <input
                  type="file"
                  id="thumbnail"
                  className="file-input"
                  accept="image/*"
                  onChange={handleThumbnailImageChange}
                  ref={thumbnailInputRef}
                />
              </div>
              {newLecture.thumbnailUrl && (
                <div className="thumbnail-preview">
                  <img src={newLecture.thumbnailUrl} alt="Thumbnail preview" />
                  <button
                    type="button"
                    onClick={handleRemoveThumbnail}
                    className="remove-thumbnail-button"
                  >
                    ✖
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="isYoutubeVideo">
              <input
                type="checkbox"
                checked={newLecture.isYoutubeVideo}
                onChange={(e) =>
                  handleInputChange("isYoutubeVideo", e.target.checked)
                }
              />
              Is this a YouTube video
            </label>
          </div>

          {newLecture.isYoutubeVideo ? (
            <div className="form-group">
              <label htmlFor="youtubeUrl">YouTube URL</label>
              <input
                type="url"
                id="youtubeUrl"
                className="form-input"
                value={newLecture.youtubeUrl}
                onChange={(e) => handleInputChange("youtubeUrl", e.target.value)}
                required={newLecture.isYoutubeVideo}
                placeholder="Paste your Youtube link here"
              />
            </div>
          ) : (
            <div className="form-group">
              <label htmlFor="videoFile">Upload Video</label>
              <div className="video-preview-container">
                <div className="video-input-wrapper">
                  <input
                    id="videoFile"
                    type="file"
                    className="file-input"
                    accept="video/*"
                    onChange={handleFileChange}
                    required={!newLecture.isYoutubeVideo}
                    ref={fileInputRef}
                  />
                </div>
                {newLecture.videoPreviewUrl && (
                  <div className="video-preview">
                    <img src={newLecture.videoPreviewUrl} alt="Video preview" />
                    <div className="play-button-overlay"></div>
                    <button
                      type="button"
                      onClick={handleRemoveVideo}
                      className="remove-thumbnail-button"
                    >
                      ✖
                    </button>
                  </div>
                )}
              </div>
              {isUploading && newLecture.progress !== null && (
                <div className="progress-bar">
                  <div
                    className="progress"
                    style={{ width: `${newLecture.progress}%` }}
                  ></div>
                </div>
              )}
              {isUploading && newLecture.progress !== null && (
                <div className="progress-percentage">
                  {Math.round(newLecture.progress)}%
                </div>
              )}
            </div>
          )}

          <div className="form-actions">
            <button
              type="submit"
              className={`submit-button ${isUploading ? "uploading" : ""}`}
              disabled={isUploading}
              style={isUploading ? {
                pointerEvents: 'none',
                cursor: 'default'
              } : undefined}
            >
              {isUploading ? <>Uploading... <span className="loading-circle"></span> </> : "Start Upload"}
            </button>
            {isUploading && (
              <button
                type="button"
                onClick={handleCancelUpload}
                className="cancel-button"
              >
                Cancel Upload
              </button>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default VideoUploader;
